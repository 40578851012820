document.addEventListener('turbolinks:load', () => {
  $('#client_client_type').on('change', function (e) {
    if (this.value === 'company_variable' || this.value === 'company_regular') {
      $('#client_business_name').parent().show();
      $('#client_rut').parent().show();
      $('#client_address').parent().show();
      $('#client_billing_contact').parent().show();
      return $('#controls').show();
    } if (this.value === 'person') {
      $('#client_business_name').parent().hide();
      $('#client_rut').parent().hide();
      $('#client_address').parent().hide();
      $('#client_billing_contact').parent().hide();
      return $('#controls').show();
    }
    return $('#controls').hide();
  });

  if ($('#client_client_type').val() === 'company_variable' || $('#client_client_type').val() === 'company_regular') {
    $('#client_business_name').parent().show();
    $('#client_rut').parent().show();
    $('#client_address').parent().show();
    $('#client_billing_contact').parent().show();
    $('#controls').show();
  } else if ($('#client_client_type').val() === 'person') {
    $('#client_business_name').parent().hide();
    $('#client_rut').parent().hide();
    $('#client_address').parent().hide();
    $('#client_billing_contact').parent().hide();
    $('#controls').show();
  } else {
    $('#controls').hide();
  }

  $('#schedule_type').on('change', function (e) {
    if (this.value === 'schedule') {
      $('.schedule').fadeIn();
    } else {
      $('.schedule').fadeOut();
    }
  });

  $('#status').on('change', function (e) {
    if ((route === 0 && $(this).val() === 'done') || $(this).val() === 'returning') {
      $('.aborted-message').fadeOut();
      $('.done-image').fadeIn();
      $('#picture').prop('required', true);
      $('#signature').prop('required', true);
      return;
    }
    if ($(this).val() === 'aborted') {
      $('.aborted-message').fadeIn();
      $('.done-image').fadeIn();
      $('#picture').prop('required', false);
      $('#signature').prop('required', false);
      return;
    }

    $('.aborted-message').fadeOut();
    $('.done-image').fadeOut();
    $('#picture').prop('required', false);
    $('#signature').prop('required', false);
  });

  $('#service_service_type').on('change', function (e) {
    const value = $(this).val();
    const express = $('#quote-express');
    const juniorExpress = $('#quote-junior-express');

    express.hide();
    juniorExpress.hide();

    if (value === 'express') {
      express.fadeIn();
      return;
    }

    juniorExpress.fadeIn();
  });
});
