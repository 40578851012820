/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import produce from 'immer';
import Address from './address';

const Addresses = ({
  addresses: _addresses, onChangeAddress, onlyShow, navigate,
}) => {
  const prepareAddresses = () => {
    if (_addresses.length === 0) return [];
    const addressesToShow = [{
      id: _.uniqueId('12345'),
      source: _addresses[0].source,
    }];
    _addresses.forEach((address) => {
      addressesToShow.push({
        id: _.uniqueId('12345'),
        source: address.destination,
      });
    });
    return addressesToShow;
  };

  const [addresses, _setAddresses] = useState(prepareAddresses);
  const addressesRef = useRef(addresses);

  // Workaround to get last state when using addListener on autocomplete
  const setAddresses = (data) => {
    addressesRef.current = data;
    _setAddresses(data);
    onChangeAddress(data);
  };

  const handleAdd = () => {
    const newAddress = {
      id: _.uniqueId('123'),
      source: '',
      toDelete: false,
    };

    setAddresses([
      ...addresses,
      newAddress,
    ]);
  };

  const onChange = (index, name, value) => {
    const newAddresses = produce(
      addressesRef.current,
      (draftState) => {
        draftState[index][name] = value;
      },
    );

    setAddresses(newAddresses);
  };

  const generateNavigationLink = () => {
    const lastDestination = addresses[addresses.length - 1];

    const parameters = {
      destination: lastDestination.source,
      travelmode: 'driving',
      dir_action: 'navigate',
      waypoints: addresses
        .filter((address) => address.id !== lastDestination.id)
        .map((address) => address.source)
        .join('|'),
    };

    const parametersToConcat = Object.entries(parameters).map(([key, value]) => `${key}=${value}`).join('&');
    const uri = `https://www.google.com/maps/dir/?api=1&${parametersToConcat}`;

    return encodeURI(uri);
  };

  useEffect(() => {
    if (addresses.length === 0) handleAdd();
  }, []);

  return (
    <>
      <div className="card mb-1">
        <div className="card-body">
          {addresses.map((address, index) => (
            <Address
              key={address.id}
              address={address}
              onChange={onChange}
              index={index}
              onlyShow={onlyShow}
            />
          ))}

          {onlyShow ? (
            navigate && (
              <a
                href={generateNavigationLink()}
                target="_blank"
                className="btn btn-primary mt-4"
                rel="noopener noreferrer"
              >
                Abrir en Google Maps
                &nbsp;
                &nbsp;
                <i className="fa fa-map" />
              </a>
            )
          ) : (
            <button
              type="button"
              className="btn btn-outline-primary btn-sm mt-1 center"
              onClick={handleAdd}
            >
              Añadir
            </button>
          )}
        </div>
      </div>
    </>
  );
};

Addresses.propTypes = {
  addresses: PropTypes.array,
  onChangeAddress: PropTypes.func,
  onlyShow: PropTypes.bool,
  navigate: PropTypes.bool,
};

Addresses.defaultProps = {
  addresses: [],
  onChangeAddress: () => { },
  onlyShow: false,
  navigate: false,
};

export default Addresses;
