import React from 'react';

const Stats = ({ stats }) => (
  <div className="text-center">
    <img
      src="/images/logo-motocity2.png"
      alt="Logo Motocity"
      className="mb-4 mx-auto img-fluid d-block mt-5"
      width="150"
    />
    <h2 className="mt-3">
      Estadísticas al momento
    </h2>

    <h4>Servicios</h4>
    <div className="stats">
      <div className="card text-center text-white bg-warning">
        <div className="card-body">
          <h5 className="card-title">En espera</h5>
          <div className="number-more card-text">
            <div>
              {stats.waiting_today}
              <div className="label">HOY</div>
            </div>
            <div>
              {stats.waiting_historical}
              <div className="label">HISTÓRICO</div>
            </div>
          </div>
        </div>
      </div>

      <div className="card text-center text-white bg-primary">
        <div className="card-body">
          <h5 className="card-title">En progreso</h5>
          <div className="number-more card-text">
            <div>
              {stats.in_progress_today}
              <div className="label">HOY</div>
            </div>
            <div>
              {stats.in_progress_historical}
              <div className="label">HISTÓRICO</div>
            </div>
          </div>
        </div>
      </div>

      <div className="card text-center text-white bg-success">
        <div className="card-body">
          <h5 className="card-title">Finalizados</h5>
          <div className="number-more card-text">
            <div>
              {stats.done_today}
              <div className="label">HOY</div>
            </div>
            <div>
              {stats.done_historical}
              <div className="label">HISTÓRICO</div>
            </div>
          </div>
        </div>
      </div>

      <div className="card text-center text-white bg-danger">
        <div className="card-body">
          <h5 className="card-title">Abortados</h5>
          <div className="number-more card-text">
            <div>
              {stats.aborted_today}
              <div className="label">HOY</div>
            </div>
            <div>
              {stats.aborted_historical}
              <div className="label">HISTÓRICO</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h4>Conductores</h4>
    <div className="stats">
      <div className="card text-center text-white bg-primary">
        <div className="card-body">
          <h5 className="card-title">En turno</h5>
          <div className="number card-text">{stats.drivers_in_shift}</div>
        </div>
      </div>

      <div className="card text-center text-white bg-success">
        <div className="card-body">
          <h5 className="card-title">Ocupados</h5>
          <div className="number card-text">{stats.drivers_in_shift_busy}</div>
        </div>
      </div>

      <div className="card text-center text-white bg-warning">
        <div className="card-body">
          <h5 className="card-title">Libres</h5>
          <div className="number card-text">{stats.drivers_in_shift_free}</div>
        </div>
      </div>
    </div>
  </div>
);

export default Stats;
