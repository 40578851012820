/* eslint-disable no-undef */
/* eslint-disable no-new */
import React, { useRef, useEffect } from 'react';

const Map = ({
  addresses, driverLocation, showDriver, optimizeRoute,
}) => {
  const mapRef = useRef();

  const addMarker = (position, map, image, title) => {
    let icon;

    if (image) {
      icon = {
        url: image,
        scaledSize: new google.maps.Size(40, 40),
      };
    }

    new google.maps.Marker({
      position,
      title,
      map,
      icon,
    });
  };

  // Add driver location to the map
  const addDriver = (map) => {
    if (!driverLocation || !showDriver) return;

    const [lat, lng] = driverLocation.split(',');

    const driverLatLng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    addMarker(driverLatLng, map, 'https://maps.google.com/mapfiles/kml/shapes/cycling.png', 'Repartidor');
  };

  const calculateAndDisplayRoute = (directionsRenderer) => {
    const waypts = addresses
      .filter((address, index) => index !== 0 && index !== address.length - 1)
      .map((address) => ({
        location: address.source,
        stopover: true,
      }));

    const origin = addresses[0].source;
    const destination = addresses.length > 1
      ? addresses[addresses.length - 1].destination
      : addresses[0].destination;

    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin,
        destination,
        waypoints: waypts,
        optimizeWaypoints: optimizeRoute || false,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        region: 'CL',
      },
      (response, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(response);
        } else {
          console.error(`Directions request failed due to ${status}`);
        }
      },
    );
  };

  // Google Maps Functions
  const getDataMaps = () => {
    const directionsRenderer = new google.maps.DirectionsRenderer();

    const map = new google.maps.Map(mapRef.current, {
      zoom: 11,
      center: new google.maps.LatLng(-33.4557967, -70.6486645),
    });
    directionsRenderer.setMap(map);

    calculateAndDisplayRoute(directionsRenderer);

    addDriver(map);
  };

  useEffect(() => {
    getDataMaps();
  }, []);

  return (
    <div ref={mapRef} className="map" />
  );
};

export default Map;
