/* eslint-disable no-undef */
import axios from 'axios';
import React, { useState } from 'react';
import Addresses from '../shared/addresses';

const Quote = ({ service, serviceType, apiUrl }) => {
  const [addresses, setAddresses] = useState(service.addresses);
  const [distance, setDistance] = useState(0);
  const [cost, setCost] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChangeAddress = (_addresses) => setAddresses(_addresses);

  const parseMoney = (amount) => new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(amount);

  const parseDistance = (amount) => new Intl.NumberFormat('es-CL').format(amount.toFixed(2));

  const handleCalculate = async () => {
    if (addresses.length < 2) return;

    setLoading(true);

    const { data } = await axios.post(`${apiUrl}/v1/services/quote`, {
      addresses: addresses
        .filter((address) => !address.toDelete)
        .map((address) => address.source),
      serviceType,
    });

    setLoading(false);

    if (!data.status) {
      setErrors(data.errors);
      setDistance(0);
      setCost(0);
      setTaxes(0);
      setTotalCost(0);
      return;
    }

    setErrors([]);
    setDistance(data.totalDistance);
    setCost(data.totalCost);
    setTaxes(data.totalCost * 0.19);
    setTotalCost(data.totalCost * 1.19);
  };

  const renderErrors = () => {
    if (errors.length === 0) return null;

    return (
      <div className="alert alert-warning mt-4" role="alert">
        <strong>Oh no!</strong>
        {' '}
        No encontramos una ruta con estas direcciones.
        Por favor, intenta nuevamente.
      </div>
    );
  };

  return (
    <div className="row">
      <div className="col-md-8">
        <fieldset>
          <legend>Ruta</legend>
          <Addresses addresses={addresses} onChangeAddress={onChangeAddress} />
        </fieldset>

        {renderErrors()}

      </div>
      <div className="col-md-4">
        <fieldset>
          <legend>Cotizador</legend>
          <h6 className="mt-3 mb-2">Información</h6>
          <table className="table table-hover">
            <tbody>
              <tr>
                <th>Kilómetros</th>
                <td className="count-kms">
                  {`${parseDistance(distance)} kms`}
                </td>
              </tr>
              <tr>
                <th>Costo Neto</th>
                <td className="total-cost">
                  {parseMoney(cost)}
                </td>
              </tr>
              <tr>
                <th>IVA</th>
                <td className="total-cost">
                  {parseMoney(taxes)}
                </td>
              </tr>
              <tr>
                <th>Total</th>
                <td className="total-cost">
                  {parseMoney(totalCost)}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="text-center">
            <button
              type="button"
              onClick={handleCalculate}
              className="btn btn-outline-secondary btn-calculate"
            >
              { loading ? 'Procesando...' : 'Calcular' }
            </button>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default Quote;
