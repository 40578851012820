/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';

const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const Address = ({
  index, address, onChange, onlyShow,
}) => {
  const [toDelete, setToDelete] = useState(false);
  const sourceElem = useRef();
  const autocompleteSourceElem = useRef();

  const handleChange = (e) => {
    onChange(index, e.target.id, e.target.value);
  };

  const handleDelete = () => {
    setToDelete(true);
    onChange(index, 'toDelete', true);
  };

  const onAutocompleteSource = () => {
    const place = autocompleteSourceElem.current.getPlace();
    onChange(index, 'source', place.formatted_address);
  };

  const enableAutocomplete = (inputRef, autocompleteRef, listener) => {
    const input = inputRef.current;

    const autocomplete = new google.maps.places.Autocomplete(input, {
      types: ['address'],
      fields: ['formatted_address'],
      componentRestrictions: { country: 'cl' },
    });
    autocompleteRef.current = autocomplete;

    autocomplete.addListener('place_changed', listener);
  };

  const disableAutocomplete = (autocompleteRef) => {
    google.maps.event.clearListeners(autocompleteRef.current, 'place_changed');
  };

  const generateNavigationLink = (_address) => {
    const parameters = {
      destination: _address,
      travelmode: 'driving',
      dir_action: 'navigate',
    };

    const parametersToConcat = Object.entries(parameters).map(([key, value]) => `${key}=${value}`).join('&');
    const uri = `https://www.google.com/maps/dir/?api=1&${parametersToConcat}`;

    return encodeURI(uri);
  };

  useEffect(() => {
    if (!onlyShow) {
      enableAutocomplete(
        sourceElem,
        autocompleteSourceElem,
        onAutocompleteSource,
      );
    }

    return () => {
      if (!onlyShow) {
        disableAutocomplete(autocompleteSourceElem);
      }
    };
  }, []);

  const renderInfo = () => {
    if (toDelete) return null;

    return (
      <div className="d-flex flex-row align-items-center my-2">
        <h4 className="m-0">
          <span className="badge badge-primary">
            { labels[index % labels.length] }
          </span>
        </h4>
        { onlyShow ? (
          <h6 className="my-0 mx-3">
            <a
              href={generateNavigationLink(address.source)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {address.source}
              &nbsp;
              &nbsp;
              <i className="fa fa-map-marker" />
            </a>
          </h6>
        ) : (
          <>
            <input
              type="text"
              ref={sourceElem}
              data-id={address.id}
              className="form-control w-100 mx-3"
              id="source"
              name={`service[addresses_attributes][${address.id}][source]`}
              placeholder="Ingrese dirección"
              onChange={handleChange}
              value={address.source}
              disabled={onlyShow}
            />
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={handleDelete}
            >
              Eliminar
            </button>
          </>
        ) }

      </div>
    );
  };

  return (
    <>
      {renderInfo()}
    </>
  );
};

export default Address;
